var exports = {};
exports = {
  "<<=": true,
  ">>=": true,
  "++": true,
  "--": true,
  "+=": true,
  "-=": true,
  "*=": true,
  "/=": true,
  "%=": true,
  "&=": true,
  "^=": true,
  "|=": true,
  "=": true
};
export default exports;