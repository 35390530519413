var exports = {};
exports = {
  "precision": true,
  "highp": true,
  "mediump": true,
  "lowp": true,
  "attribute": true,
  "const": true,
  "uniform": true,
  "varying": true,
  "break": true,
  "continue": true,
  "do": true,
  "for": true,
  "while": true,
  "if": true,
  "else": true,
  "in": true,
  "out": true,
  "inout": true,
  "true": true,
  "false": true,
  "return": true
};
export default exports;